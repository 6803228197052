// Import React and ReactDOM
import React from "react";
import ReactDOM from "react-dom";

// Redux
import { Provider } from "react-redux";

// Import icons
import "@/css/fontawesome/css/fontawesome.min.css";
import "@/css/fontawesome/css/brands.min.css";
import "@/css/fontawesome/css/custom-icons.min.css";
import "@/css/fontawesome/css/regular.min.css";
import "@/css/fontawesome/css/solid.min.css";

// Import Framework7
import Framework7 from "framework7/framework7-lite.esm.bundle.js";

// Import Framework7-React Plugin
import Framework7React from "framework7-react";

// Import Framework7 Styles
import "framework7/css/framework7.bundle.css";
import "framework7-icons";

// Import Icons and App Custom Styles
import "@/css/app.scss";

// Init F7 React Plugin
Framework7.use(Framework7React);

const i18n = () => {
  const controller = new AbortController();

  // 5 second timeout:
  const timeoutId = setTimeout(() => controller.abort(), 5000);

  return fetch("https://api.racketscore.com/languages/", {
    signal: controller.signal,
  })
    .then((response) => {
      clearTimeout(timeoutId);
      return response.json();
    })
    .catch(() => null);
};

const initApp = () => {
  import("./store")
    .then(({ initStore }) => initStore())
    .then((store) => Promise.all([store, import("@/Components/App"), i18n()]))
    .then(([store, { default: App }, i18n]) => {
      // Dispatch i18n
      if (i18n) {
        store.dispatch({ type: "SET_LOCALE", ...i18n });
      }

      // Mount React App
      ReactDOM.render(
        <Provider store={store}>
          <App />
        </Provider>,
        document.getElementById("app")
      );
    });
};

if (!!window.cordova) {
  document.addEventListener("deviceready", initApp, false);
} else {
  initApp();
}
